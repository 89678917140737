import "swiper/dist/css/swiper.min.css";
import "./swiper.scss";

import PropTypes from "prop-types";
import React from "react";
import SwiperClass from "swiper";

const swiper = Symbol("swiper");

export default class Swiper extends React.Component {
	static propTypes = {
		images: PropTypes.arrayOf(PropTypes.shape({
			description: PropTypes.string,
			src: PropTypes.string
		})).isRequired
	};

	componentDidMount() {
		this[swiper] = new SwiperClass(".swiper-container", {
			direction: "horizontal",
			loop: true,
			pagination: {
				el: ".swiper-pagination"
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev"
			}
		});
	}

	renderImage({ src, description }) {
		return (
			<div key={src} className="swiper-slide">
				<img alt={description} className="swiper-image" src={src}/>
			</div>
		);
	}

	render() {
		return (
			<div className="swiper-container">
				<div className="swiper-wrapper">
					{this.props.images.map(this.renderImage)}
				</div>
				<div className="swiper-pagination"/>
				<div className="swiper-button-prev"/>
				<div className="swiper-button-next"/>
				<div className="swiper-scrollbar"/>
			</div>
		);
	}
}
