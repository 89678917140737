import "./users.scss";

// import database from "../../../models/database";
import Loading from "../../../components/loading/loading";
import React from "react";
import { Row } from "reactstrap";
import User from "../../../components/sbo/user/user";

const database = {};

export default class Users extends React.Component {
	timer = null;

	state = {
		users: null,
		search: ""
	};

	componentDidMount() {
		database.fetchUsers()
			.promise
			.then((users) => {
				this.setState({ users });
			});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.search !== prevState.search) {
			database.fetchUsers({ filter: this.state.search })
				.promise
				.then((users) => {
					this.setState({ users });
				});
		}
	}

	onSearch(event) {
		if (this.timer) {
			clearTimeout(this.timer);
		}

		let search = event.target.value;

		this.timer = setTimeout(() => {
			this.setState({ search });
		}, 300);
	}

	render() {
		if (!this.state.users) {
			return <Loading className="users"/>;
		}

		if (!this.state.users.length) {
			return "No users found";
		}

		let { users } = this.state;

		return (
			<div className="page sbo users">
				<h3>{"Gestionnaire d'utilisateurs"}</h3>
				<Row>
					{users.map((user) => <User
						key={user.email()}
						user={user}
					/>)}
				</Row>
			</div>
		);
	}
}
