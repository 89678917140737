import Admin from "./admin";
import Anonymous from "./anonymous";
import User from "./user";

/**
 * Instantiate a User based on its type
 * @param {Object} userData - User specification
 * @return {User} User instance
 */
export default function instantiateUser(userData) {
	if (!userData) {
		return new Anonymous();
	}

	let Model = User;
	if (userData.role === "admin") {
		Model = Admin;
	}

	return new Model(userData);
}
