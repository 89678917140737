import "./info.scss";
import { Col, Container, Row } from "reactstrap";
import AbstractSectionPage from "../abstractSectionPage";
import React from "react";
import Swiper from "../../components/swiper/swiper";

export default class Info extends AbstractSectionPage {
	sections = [
		{
			title: "Le cabinet",
			path: "office",
			text: (
				<div>
					<p>
						{"Situé au "}<strong>{"9 bis rue de Clermont à Compiègne"}</strong>{", le cabinet se trouve à proximité immédiate " +
						"de la gare, du centre ville, mais aussi de Margny-lès-Compiègne et de Venette. " +
						"Vous pourrez bénéficier d'un stationnement aisé à proximité."}
					</p>
					<Container>
						<Row>
							<Col md="12">
								<iframe allowFullScreen="" height="450" loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d648.8673346462233!2d2.8183921292999603!3d49.41894027112886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e7d7341b528815%3A0x4381cca3bdd2d8c4!2sSalwa%20EL%20FAROUK!5e0!3m2!1sfr!2sfr!4v1644952284504!5m2!1sfr!2sfr" style={{
									border: 0,
									marginBottom: 20
								}} title="Google Map" width="100%"/>
							</Col>
						</Row>
					</Container>
					<Container>
						<Row>
							<Col md="12">
								<Swiper images={[
									{
										src: "/info/office1.jpg",
										description: "office"
									},
									{
										src: "/info/office2.jpg",
										description: "office"
									}, {
										src: "/info/office3.jpg",
										description: "office"
									}
								]}/>
							</Col>
						</Row>
					</Container>
				</div>
			)
		},
		{
			title: "Horaires",
			path: "schedule",
			text: (
				<div>
					{"Le cabinet est ouvert les lundi, mardi, jeudi et vendredi. N'hésitez à me contacter pour connaitre mes disponibilités horaires."}
				</div>
			)
		},
		{
			title: "Honoraires",
			path: "pricing",
			text: (
				<div>
					<p>{"Le tarif d'une consultation est de "}<strong>65€</strong>{" pour les séances individuelles, et de "}<strong>70€</strong>{" pour les séances de couple. Pour les entreprises, merci de me contacter afin d'établir ensemble un devis."}
					</p>
					<p>{"Le règlement peut s'effectuer par espèces, chèques ou virement."}</p>
					<p>{"Les consultations de psychologue ne sont pas prises en charge par la Sécurité Sociale. Cependant, votre mutuelle couvre peut-être ce type de soins. N'hésitez pas à vous renseigner auprès de votre organisme. Une facture vous sera fournie sur demande."}</p>
				</div>
			)
		}
	];

	pageClassName() {
		return "info";
	}

	pageTitle() {
		return "Salwa El Farouk - Psychologue à Compiègne (Oise) - Informations";
	}

	pageDescription() {
		return "Informations à propos de mon cabinet au 9 bis rue de Clermont à Compiègne 60200 Oise";
	}

	renderTitle() {
		return "Informations pratiques";
	}
}
