import features from "../features";
import PropTypes from "prop-types";
import React from "react";
import { Switch } from "react-router-dom";

export default class FeatureSwitch extends React.Component {
	static propTypes = {
		children: PropTypes.node.isRequired
	};

	render() {
		let matchingChildren = this.props.children.filter((route) => !route.props.name ||
			features.isPageEnabled(route.props.name));

		let newProps = Object.assign({}, this.props, { children: matchingChildren });

		return (
			<Switch {...newProps}/>
		);
	}
}
