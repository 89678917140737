// import i18n from "../i18n";
import React from "react";
import Translate from "../components/translate";

const i18n = {
	t(key, value) { return value; }
};

class Contacts {
	rawEmail() {
		return i18n.t("contacts:email", "salwa.el.farouk.psychologue@gmail.com");
	}

	email() {
		return (
			<a className="contacts email" href={`mailto:${this.rawEmail()}`}>
				<Translate i18nKey="contacts:email">
					salwa.el.farouk.psychologue@gmail.com
				</Translate>
			</a>
		);
	}

	rawPhone() {
		return i18n.t("contacts:phone", "03 67 47 06 79");
	}

	phone() {
		return (
			<a className="contacts phone" href={`tel:${this.rawPhone()}`}>
				<Translate i18nKey="contacts:phone">
					03 67 47 06 79
				</Translate>
			</a>
		);
	}

	siret() {
		return "83221859800015";
	}

	adeli() {
		return "609305800";
	}
}

export default new Contacts();
