import "./home.scss";
import { Col, Container, Row } from "reactstrap";
import crayons from "./crayons.webp";
import { Helmet } from "react-helmet";
import me from "./me.webp";
import mentalHealth from "./mental-health.webp";
import question from "./question.webp";
import React from "react";

export default class Home extends React.Component {
	sections = [
		{
			text: "J'exerce aujourd'hui à mon compte après plus de 10 de travail au sein de diverses institutions. Cette pluralité m'a apporté une large palette d'outils  ainsi qu'un réseau de professionels compétents, à mettre au service de mes patients",
			image: mentalHealth
		},
		{
			text: "Lors de ma formation et de mes expériences professionnelles, j'ai pu acquérir le savoir faire nécessaire à l'évaluation et l'accompagnement des troubles psychiques chez l'adulte, qu'ils s'inscrivent dans un vécu émotionnel d'évènements de vie douloureux, ou dans la pathologie mentale (Centre Médico-Psychologique, maison de retraite, Hôpital de jour psychiatrique).",
			image: question
		},
		{
			text: "Dans mon parcours professionnel, j'ai également pu exercer auprès d'enfants et d'adolescents aux prises avec des troubles psychiques dans un contexte de vie difficile. Cette pratique m'a enseigné l'importance pour le psychologue d'adapter sa pratique et ses outils thérapeutiques à ces deux âges de la vie. Aussi je suis à l'écoute de la nécessité du recours au jeu, à l'imaginaire, ainsi qu'à différents médias, pour instaurer une relation de confiance et permettre un travail psychique.",
			image: crayons
		}
	];

	renderIntroduction() {
		return (
			<Container className="introduction">
				<Row>
					<Col md="6 offset-md-3">
						<img alt="Salwa El Farouk" className="introduction-image" src={me}/>
					</Col>
				</Row>
				<Row>
					<Col md="6 offset-md-3">
						<p>{`
								Bonjour, psychologue installée à Compiègne, je vous accueille pour un suivi ponctuel ou
								une psychothérapie au sein de mon cabinet.
								Diplômée de l'`}<a href="http://ufrpsycho.unicaen.fr/">Université de Caen</a>{`, j'y ai
								obtenu en 2010 un Master en Psychologie Clinique et Psychopathologie.
						`}</p>
					</Col>
				</Row>
			</Container>
		);
	}

	renderSection({ text, image, disabled = false }, index) {
		if (disabled) {
			return null;
		}

		return (
			<Row key={index} className="section">
				<Col className="image-holder" md="3"><img alt="issue" className="section-image" src={image}/></Col>
				<Col className="text-holder" md="9"><p className="section-text">{text}</p></Col>
			</Row>
		);
	}

	render() {
		return (
			<div className="page home">
				<Helmet>
					<title>Salwa El Farouk - Psychologue à Compiègne (Oise)</title>
					<meta
						content={"Salwa El Farouk, Psychologue à Compiègne (60200) dans l'Oise. Consultations individuelles, en couple, en famille, pour adulte ou enfant."}
						name="description"/>
				</Helmet>
				<Container>
					{this.renderIntroduction()}
					{this.sections.map(this.renderSection)}
				</Container>
			</div>
		);
	}
}
