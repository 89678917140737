import AbstractSectionPage from "../abstractSectionPage";
import PropTypes from "prop-types";
import React from "react";

export default class About extends AbstractSectionPage {
	static propTypes = {
		routing: PropTypes.object.isRequired
	};

	sections = [
		{
			title: "Les différents \"psy\"",
			disabled: true,
			path: "different-psy",
			text: (
				<div>
					{"Il existe différentes professions centrées sur l'accompagnement psychique et le traitement des désordres psychologiques."}
					<ul>
						<li>
							<div>
								Le psychologue
							</div>
							<p>
								{"Lorsque l'on pense à consulter un \"psy\", le plus souvent, il s'agit d'un psychologue. Le titre de psychologue est règlementé. Pour l'obtenir, il faut un master en psychologie (BAC + 5)." +
								"Chaque psychologue dispose d'un numéro d'identification délivré par l'Agence Régionale de Santé, nommé \"numéro ADELI\"."}
							</p>
							<p>{"Disposant d'approches théoriques et d'outils pratiques variés, le psychologue a pour principaux objectifs"}</p>
							<ul>
								<li>
									{"Accompagner la souffrance psychique et soutenir la personne lors de moments de vie douloureux"}
								</li>
								<li>
									{"Apporter un éclairage nouveau sur une situation ou une difficulté afin d'aider la personne à trouver une issue"}
								</li>
								<li>
									{"Réaliser des évaluations par le biais de tests"}
								</li>
							</ul>
						</li>
						<li>
							<div>
								Le psychiatre
							</div>
							<p>
								{"Il s'agit d'un médecin, qui s'est spécialisé en psychiatrie. Il peut être formé à la psychothérapie et avoir complété sa formation par des études de psychologie. Il peut-être ammené à delivrer des médicaments en cas de besoin."}
							</p>
						</li>
					</ul>
				</div>

			)
		},
		{
			title: "Première consultation",
			path: "first-consultation",
			text: (
				<div>
					<p>{"Sauter le pas d'entrer en contact avec un psychologue vous inscrit déjà dans une démarche active vers le mieux-être."}</p>
					<p>{"Lors de votre première consultation, nous commencerons par analyser votre demande. Nous pourrons ensuite definir ensemble les moyens à metre en oeuvre pour vous accompagner au mieux. Nous conviendrons également du cadre concret de nos rencontres futures."}</p>
					<p>{"Cette première séance sera l'occasion pour vous de vous familiariser avec ma pratique ainsi que le cadre qui vous sera offert lors de nos séances. Cette séance me permettra d'apprendre à vous connaitre afin de trouver le meilleur moyen de vous venir en aide."}</p>
				</div>
			)
		},
		{
			title: "Accompagnement psy d'un enfant",
			path: "child-support",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliter enim explicari, quod quaeritur, non potest. Duo Reges: constructio interrete. Duae sunt enim res quoque, ne tu verba solum putes. Nulla profecto est, quin suam vim retineat a primo ad extremum. Incommoda autem et commoda-ita enim estmata et dustmata appello-communia esse voluerunt, paria noluerunt. Post enim Chrysippum eum non sane est disputatum. Quasi ego id curem, quid ille aiat aut neget. Falli igitur possumus."
		},
		{
			title: "Accompagnement ponctuel et psychothérapie adulte",
			path: "psychotherapy",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliter enim explicari, quod quaeritur, non potest. Duo Reges: constructio interrete. Duae sunt enim res quoque, ne tu verba solum putes. Nulla profecto est, quin suam vim retineat a primo ad extremum. Incommoda autem et commoda-ita enim estmata et dustmata appello-communia esse voluerunt, paria noluerunt. Post enim Chrysippum eum non sane est disputatum. Quasi ego id curem, quid ille aiat aut neget. Falli igitur possumus."
		},
		{
			title: "Entretien de couple et familial",
			path: "couple-therapy",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliter enim explicari, quod quaeritur, non potest. Duo Reges: constructio interrete. Duae sunt enim res quoque, ne tu verba solum putes. Nulla profecto est, quin suam vim retineat a primo ad extremum. Incommoda autem et commoda-ita enim estmata et dustmata appello-communia esse voluerunt, paria noluerunt. Post enim Chrysippum eum non sane est disputatum. Quasi ego id curem, quid ille aiat aut neget. Falli igitur possumus."
		}
	];

	pageClassName() {
		return "about-page";
	}

	pageTitle() {
		return "Salwa El Farouk - Psychologue à Compiègne (Oise) - À propos";
	}

	renderIntroduction() {
		return (
			<div>Introduction text</div>
		);
	}
}
