import User from "./user";

const usedRole = Symbol("usedRole");

export default class Admin extends User {
	constructor({ usedRole: userUsedRole }) {
		super(...arguments); // eslint-disable-line prefer-rest-params
		this[usedRole] = userUsedRole;
	}

	showSuperBO = () => true;
}
