import "./boMenu.scss";

import { Button } from "reactstrap";
import React from "react";
import session from "../../../models/session";

export default class BoMenu extends React.Component {
	render() {
		return (
			<div className="bo-menu">
				<ul>
					<li>
						<Button color="secondary" onClick={() => session.logout()}>Logout</Button>
					</li>
				</ul>
			</div>
		);
	}
}
