import "bootstrap/dist/css/bootstrap.min.css";
import "./app.scss";
import "./polyfill/date";

import { Route, withRouter } from "react-router-dom";
import About from "./pages/about/about";
import Anonymous from "./models/users/anonymous";
import BoMenu from "./components/menus/boMenu/boMenu";
import Book from "./pages/book/book";
import FeatureSwitch from "./helpers/components/featureSwitch";
import Footer from "./components/footer/footer";
import { Helmet } from "react-helmet";
import Home from "./pages/home/home";
import Info from "./pages/info/info";
import Loading from "./components/loading/loading";
import Login from "./pages/login/login";
import Menu from "./components/menus/menu/menu";
import NoMatch from "./pages/noMatch";
import PropTypes from "prop-types";
import React from "react";
import SBO from "./pages/sbo/sbo";

// import session from "./models/session";
import SuperBoMenu from "./components/menus/superBoMenu/superBoMenu";
import { UncontrolledAlert } from "reactstrap";
import Why from "./pages/why/why";

const MESSAGE_TIME = 3000;

class App extends React.Component {
	static propTypes = {
		location: PropTypes.object.isRequired
	};

	state = {
		currentUser: new Anonymous(),
		isUserLoading: false,
		isLoading: false,
		messages: {},
		lastMessageIndex: 0
	};

	componentDidMount() {
		/*
		 * session.currentUser()
		 * 	.then((currentUser) => this.setState({ currentUser }));
		 *
		 * session.onUserChange({
		 * 	callback: (currentUser) => {
		 * 		this.setState({
		 * 			currentUser,
		 * 			isUserLoading: false
		 * 		});
		 * 	},
		 * 	loading: () => {
		 * 		this.setState({ isUserLoading: true });
		 * 	}
		 * });
		 */

		/*
		 * i18n.onLanguageChanged({
		 * 	loaded: () => {
		 * 		this.setState({ isLoading: false });
		 * 	},
		 * 	loading: () => {
		 * 		this.setState({ isLoading: true });
		 * 	}
		 * });
		 */
	}

	renderSuperBO(langRouting) {
		if (!this.state.currentUser.showSuperBO()) {
			return null;
		}

		return this.wrapComponent(SuperBoMenu, langRouting);
	}

	renderBO(langRouting) {
		if (!this.state.currentUser.showBO()) {
			return null;
		}

		return this.wrapComponent(BoMenu, langRouting);
	}

	showMessage(message) {
		this.setState((previousState) => {
			let index = previousState.lastMessageIndex;

			setTimeout(() => {
				this.setState((previousInnerState) => {
					let messages = Object.assign({}, previousInnerState.messages);
					delete messages[index];
					return { messages };
				});
			}, MESSAGE_TIME);

			return {
				messages: Object.assign({}, previousState.messages, { [index]: message }),
				lastMessageIndex: index + 1
			};
		});
	}

	wrapComponent(WrappedComponent, routing) {
		routing.match.sanitizedUrl = routing.match.url.replace(/\/$/, "");

		return (
			<WrappedComponent
				currentUser={this.state.currentUser}
				routing={routing}
				showMessage={this.showMessage.bind(this)}
			/>
		);
	}

	componentDidUpdate() {
		/*
		 * let { lang } = this;
		 * if (lang) {
		 * 	i18n.changeLanguage(lang);
		 * }
		 */
	}

	renderMessages() {
		let messages = Object.values(this.state.messages).map((message, index) => (
			<UncontrolledAlert key={index} color="success">
				{message}
			</UncontrolledAlert>
		));

		return (
			<>
				{messages}
			</>
		);
	}

	renderContent() {
		return (
			<Route path="/:lang([a-z]{2}-[A-Z]{2})?" render={(langRouting) => {
				let { match: { url, params: { lang } } } = langRouting;
				if (lang && lang !== this.lang) {
					this.lang = lang;
				}

				return (
					<div className={`wrapper ${this.state.currentUser ? "logged" : ""}`}>
						{this.renderSuperBO(langRouting)}
						{this.renderBO(langRouting)}
						{this.wrapComponent(Menu, langRouting)}
						<div className={"app-content"}>
							<FeatureSwitch>
								<Route exact component={Home} name="home" path={`${url}/`}/>
								<Route
									name="about"
									path={`${url}/about/(.*)?`}
									render={(routing) => {
										routing.match.sanitizedPath = routing.match.path.slice(0, -"/(.*)?".length);
										return this.wrapComponent(About, routing);
									}}
								/>
								<Route
									name="pourquoi"
									path={`${url}/pourquoi/(.*)?`}
									render={(routing) => {
										routing.match.sanitizedPath = routing.match.path.slice(0, -"/(.*)?".length);
										return this.wrapComponent(Why, routing);
									}}
								/>
								<Route
									name="info"
									path={`${url}/info/(.*)?`}
									render={(routing) => {
										routing.match.sanitizedPath = routing.match.path.slice(0, -"/(.*)?".length);
										return this.wrapComponent(Info, routing);
									}}
								/>
								<Route name="reserver" path={`${url}/reserver`} render={(routing) => this.wrapComponent(Book, routing)}/>
								<Route name="login" path={`${url}/login`} render={(routing) => this.wrapComponent(Login, routing)}/>
								<Route name="sbo" path={`${url}/sbo`} render={(routing) => this.wrapComponent(SBO, routing)}/>
								<Route render={(routing) => this.wrapComponent(NoMatch, routing)}/>
							</FeatureSwitch>
						</div>
						<Footer/>
					</div>
				);
			}}/>
		);
	}

	renderHelmet() {
		if (window.location.hostname === "psychologue.el-farouk.fr") {
			return null;
		}

		return (
			<Helmet>
				<link href={`https://psychologue.el-farouk.fr${this.props.location.pathname}`} rel="canonical"/>
			</Helmet>
		);
	}

	render() {
		if (this.state.isLoading || this.state.isUserLoading) {
			return <Loading className="app"/>;
		}

		return (
			<React.Fragment>
				{this.renderHelmet()}
					<div className="alert-area">
						{this.renderMessages()}
					</div>
					{this.renderContent()}
			</React.Fragment>
		);
	}
}

export default withRouter(App);
