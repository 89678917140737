import "./footer.scss";
import { Col, Container, Row } from "reactstrap";
import contacts from "../../helpers/contacts";
import React from "react";

export default class Footer extends React.Component {
	render() {
		return (
			<div className="footer">
				<Container>
					<Row>
						<Col md="4" sm="6">
							<div className="no-mobile">{contacts.email()}</div>
							<div>{contacts.phone()}</div>
						</Col>
						<Col className="no-mobile d-block d-sm-none d-md-block" md="4">
							<div>9 bis rue de Clermont</div>
							<div>60200 Compiègne</div>
						</Col>
						<Col className="no-mobile" md="4" sm="6">
							<div>Numéro ADELI : {contacts.adeli()}</div>
							<div>Numéro SIRET : {contacts.siret()}</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

Footer.propTypes = {};
