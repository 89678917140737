import "./abstractSectionPage.scss";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import arabesque from "./arabesque.svg";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React from "react";

export default class AbstractSectionPage extends React.Component {
	static propTypes = {
		routing: PropTypes.object.isRequired
	};

	sections = [];

	activeSections() {
		return this.sections.filter((each) => !each.disabled);
	}

	renderNav(section, index) {
		let active = this.props.routing.match.url === `${this.props.routing.match.sanitizedPath}/${section.path}`;

		return (
			<NavItem key={index}>
				<Link
					className={`nav-link ${active ? "active" : ""}`}
					to={`${this.props.routing.match.sanitizedPath}/${section.path}`}>
					{section.title}
				</Link>
			</NavItem>
		);
	}

	renderSection(section) {
		return (
			<div className="section">
				<h3 className="section-title">{section.title}</h3>
				<div className="section-content">{section.text}</div>
			</div>
		);
	}

	renderSectionRoute(section, index) {
		return (
			<Route
				key={index}
				path={`${this.props.routing.match.sanitizedPath}/${section.path}`}
				render={() => this.renderSection(section, index)}/>
		);
	}

	renderIntroduction() {
		if (!this.introduction) {
			return (
				<Redirect to={`${this.props.routing.match.sanitizedPath}/${this.sections[0].path}`}/>
			);
		}

		return this.introduction;
	}

	pageClassName() {
		throw new Error("Must be overridden");
	}

	renderTitle() {
		throw new Error("Must be overridden");
	}

	renderIntroducionLink() {
		if (!this.introduction) {
			return null;
		}

		let isIntroductionActive = this.props.routing.match.url === this.props.routing.match.sanitizedPath;

		return (
			<NavItem>
				<Link
					className={`nav-link ${isIntroductionActive ? "active" : ""}`}
					to={`${this.props.routing.match.sanitizedPath}`}>
					Introduction
				</Link>
			</NavItem>
		);
	}

	renderSeparator() {
		return (
			<div className="mobile-only section-separator">
				<img alt="separator" src={arabesque}/>
			</div>
		);
	}

	renderOnPhone() {
		let sections = this.activeSections();
		return (
			<div className={`section-page page ${this.pageClassName()}`}>
				<h2 className="section-main-title">{this.renderTitle()}</h2>
				<div className="section-wrapper">
					{this.renderIntroduction()}
					{sections.map((section, index) => (
						<>
							{this.renderSeparator()}
							{this.renderSection.bind(this)(section, index)}
						</>
					))}
				</div>
			</div>
		);
	}

	render() {
		if (window.matchMedia("(max-width: 767.98px)").matches) {
			return this.renderOnPhone();
		}

		let sections = this.activeSections();

		return (
			<div className={`section-page page ${this.pageClassName()}`}>
				<Helmet>
					<title>{this.pageTitle()}</title>
					<meta
						content={this.pageDescription()}
						name="description"/>
				</Helmet>
				<h2 className="section-main-title">{this.renderTitle()}</h2>
				<div className="section-menu">
					<Nav>
						{this.renderIntroducionLink()}
						{sections.map(this.renderNav.bind(this))}
					</Nav>
				</div>
				<div className="section-wrapper">
					<Switch>
						{sections.map(this.renderSectionRoute.bind(this))}
						<Route render={this.renderIntroduction.bind(this)}/>
					</Switch>
				</div>
			</div>
		);
	}
}
