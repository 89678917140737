import { Route, Switch, withRouter } from "react-router-dom";
import NoMatch from "../noMatch";
import PropTypes from "prop-types";
import React from "react";
import Users from "./users/users";

class SBO extends React.Component {
	static propTypes = {
		currentUser: PropTypes.object.isRequired,
		match: PropTypes.object.isRequired
	};

	render() {
		if (!this.props.currentUser.showSuperBO()) {
			return <NoMatch/>;
		}

		return (
			<Switch>
				<Route exact component={Users} name="users" path={`${this.props.match.url}/users`}/>
			</Switch>
		);
	}
}

export default withRouter(SBO);
