import toSnakeCase from "to-snake-case";

/**
 * Convert CLI value to JS in a safe way
 * @param {string} value - Value to convert
 * @return {*} JS value converted
 */
function convertValue(value) {
	if (value === "true") {
		return true;
	}

	if (value === "false") {
		return false;
	}

	if (value === "null") {
		return null;
	}

	if (value === "undefined") {
		return null;
	}

	if (!isNaN(+value)) {
		return +value;
	}

	return value;
}

class Features {
	buildFeatureEnvName(name) {
		return `REACT_APP_FEATURES_${toSnakeCase(name).toUpperCase()}`;
	}

	buildPageEnvName(page) {
		return `REACT_APP_PAGES_${toSnakeCase(page).toUpperCase()}`;
	}

	checkEnvVar(name) {
		return convertValue(process.env[name]); // eslint-disable-line no-process-env
	}

	isOnlineBookingEnabled() {
		return this.checkEnvVar(this.buildFeatureEnvName("online booking"));
	}

	isPageEnabled(page) {
		return this.checkEnvVar(this.buildPageEnvName(page)) !== false;
	}
}

export default new Features();
