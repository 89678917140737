import React from "react";

export default class Translate extends React.Component {
	render() {
		// eslint-disable-next-line react/prop-types
		return this.props.children;
	}
}

export class Interpolate extends React.Component {
	render() {
		// eslint-disable-next-line react/prop-types
		return this.props.children;
	}
}

