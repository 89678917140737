import "./user.scss";

import { Card, CardBody, CardFooter, CardHeader, CardLink, CardTitle, Col } from "reactstrap";
import gravatar from "gravatar";
import PropTypes from "prop-types";
import React from "react";
import Translate from "../../translate.js";

export default class User extends React.Component {
	static defaultProps = {};

	static propTypes = {
		user: PropTypes.object.isRequired
	};

	render() {
		let { user } = this.props;
		let photoUrl = gravatar.url(user.email(), { d: "retro" }); // eslint-disable-line id-length
		return (
			<Col className="user" data-id={user.email()} md={4}>
				<Card>
					<CardHeader>
						<img alt={user.email()} className="card-avatar" src={photoUrl}/>
						<CardTitle className="user-name">
							{user.name()}
						</CardTitle>
					</CardHeader>
					<CardBody>
						{user.role()}
					</CardBody>
					<CardFooter>
						<CardLink href={`mailto:${user.email()}`}>
							<Translate i18nKey="user:contact">Contacter</Translate>
						</CardLink>
					</CardFooter>
				</Card>
			</Col>
		);
	}
}
