import "./superBoMenu.scss";

import { Nav, NavItem } from "reactstrap";
import features from "../../../helpers/features";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import Translate from "../../translate.js";

export default class SuperBoMenu extends React.Component {
	static propTypes = {
		routing: PropTypes.object.isRequired
	};

	links = [
		{
			title: <Translate i18nKey="menu:sbo.users">Gestion des utilisateurs</Translate>,
			name: "sbo-users",
			path: "/sbo/users"
		}
	];

	renderLink(link, index) {
		if (!features.isPageEnabled(link.name)) {
			return null;
		}

		let base = this.props.routing.match.sanitizedUrl;

		return (
			<NavItem key={index}>
				<Link className={`nav-link ${link.className}`} to={base + link.path}>{link.title}</Link>
			</NavItem>
		);
	}

	render() {
		return (
			<div className="super-bo-menu">
				<Nav navbar className="ml-auto ms-auto">
					{this.links.map(this.renderLink.bind(this))}
				</Nav>
			</div>
		);
	}
}
