import "./loading.scss";

import PropTypes from "prop-types";
import React from "react";
import ReactLoading from "react-loading";

export default class Loading extends React.Component {
	static defaultProps = {
		className: "",
		full: false,
		dark: true
	};

	static propTypes = {
		className: PropTypes.string,
		dark: PropTypes.bool,
		full: PropTypes.bool
	};

	render() {
		return (
			<div className={`loading-wrapper ${this.props.dark ? "dark" : ""} ${this.props.full ? "full" : ""}`}>
				<div className={`loading ${this.props.className}`}>
					<ReactLoading type="spinningBubbles"/>
				</div>
			</div>
		);
	}
}
