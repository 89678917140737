import React from "react";
import session from "../../models/session";

export default class Login extends React.Component {
	state = {
		email: "",
		password: ""
	};

	render() {
		return (
			<div className="page login">
				<input type="email"
					value={this.state.email}
					onChange={(event) => this.setState({ email: event.target.value })}
				/>
				<input
					type="password"
					value={this.state.password}
					onChange={(event) => this.setState({ password: event.target.value })}
				/>
				<button
					className="btn btn-outline-primary"
					onClick={() => session.login(this.state.email, this.state.password)}
				>
					Login
				</button>
			</div>
		);
	}
}
