import React from "react";

export default class NoMatch extends React.Component {
	render() {
		return (
			<div>
				TODO NoMatch
			</div>
		);
	}
}

NoMatch.propTypes = {};
