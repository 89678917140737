const email = Symbol("email");
const name = Symbol("name");
const firstName = Symbol("firstName");
const role = Symbol("role");

export default class User {
	constructor({ email: userEmail, name: userName, firstName: userFirstName, role: userRole } = {}) {
		this[email] = userEmail;
		this[name] = userName;
		this[firstName] = userFirstName;
		this[role] = userRole;
	}

	name() {
		return `${this[firstName]} ${this[name]}`;
	}

	email() {
		return this[email];
	}

	role() {
		return this[role];
	}

	showSuperBO = () => false;

	showBO = () => true;
}
