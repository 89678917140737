import "./menu.scss";

import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from "reactstrap";
import features from "../../../helpers/features";
import { Link } from "react-router-dom";
import logo from "./brand.png";
import PropTypes from "prop-types";
import React from "react";
import Translate from "../../translate.js";

export default class Menu extends React.Component {
	static propTypes = {
		routing: PropTypes.object.isRequired
	};

	links = [
		{
			title: <Translate i18nKey="menu:about">À propos</Translate>,
			name: "about",
			path: "/about"
		},
		{
			title: <Translate i18nKey="menu:why">Pourquoi consulter ?</Translate>,
			name: "pourquoi",
			path: "/pourquoi"
		},
		{
			title: <Translate i18nKey="menu:info">Informations pratiques</Translate>,
			name: "info",
			path: "/info"
		},
		{
			title: <Translate i18nKey="menu:book">Prendre rendez-vous</Translate>,
			name: "reserver",
			path: "/reserver",
			className: "btn book-btn btn-outline-success"
		}
	];

	state = {
		isOpen: false
	};

	close() {
		this.setState({
			isOpen: false
		});
	}

	toggle() {
		this.setState(({ isOpen }) => ({
			isOpen: !isOpen
		}));
	}

	renderLink(link, index) {
		if (!features.isPageEnabled(link.name)) {
			return null;
		}

		let base = this.props.routing.match.sanitizedUrl;

		return (
			<NavItem key={index}>
				<Link className={`nav-link ${link.className}`} to={base + link.path} onClick={this.close.bind(this)}>{link.title}</Link>
			</NavItem>
		);
	}

	render() {
		let base = this.props.routing.match.sanitizedUrl;

		return (
			<div>
				<Navbar light className="menu" color="light" expand="md">
					<Link className="navbar-brand" to={base} onClick={this.close.bind(this)}>
						<div className="brand-image">
							<img alt="logo" src={logo}/>
						</div>
						<span className="brand-name">
							<div>Salwa El Farouk</div>
							<div>Psychologue</div>
						</span>
					</Link>
					<NavbarToggler arialLabel="menu" onClick={this.toggle.bind(this)}/>
					<Collapse navbar isOpen={this.state.isOpen}>
						<Nav navbar className="ml-auto ms-auto">
							{this.links.map(this.renderLink.bind(this))}
						</Nav>
					</Collapse>
				</Navbar>
			</div>
		);
	}
}
