import AbstractSectionPage from "../abstractSectionPage";
import React from "react";

export default class Why extends AbstractSectionPage {
	introduction = (
		<div>
			<p>
				{"S'inscrire dans la démarche de rencontrer un psychologue c'est se positionner dans une volonté de compréhension " +
				"des difficultés que vous rencontrer, en posant des mots sur votre vécu et vos émotions."}
			</p>
			<p>
				{"En investissant cette espace qui s'offre à vous, vous allez apprendre à mieux vous connaitre, et acquérir de nouvelles ressources pour être acteur de votre épanouissement. " +
				"Disponible et à l'écoute, j'accueillerai vos questionnements dans un cadre bienveillant. Ainsi vous pourrez avancer sereinement dans votre cheminement, et dépasser les difficultés que vous rencontrez."}
			</p>
			<p>
				{"Je consulte auprès d'enfants, d'adolescents et d'adultes. Je propose également des consultations de couple ou familiale. S'il n'existe pas de liste exhaustive de ce qui peut vous amener à voir un psychologue, voici quelques exemples des mes domaines d'interventions."}
			</p>
		</div>
	);

	sections = [
		{
			title: "Chez l'adulte",
			path: "adulte",
			text: (
				<ul>
					<li>{"Évenements de vie douloureux : séparation, deuil, baby-blues, maladies somatiques, traumatismes récents ou passés"}</li>
					<li>{"Difficultés liées au monde du travail : stress, perte de sens, burn-out, licenciement"}</li>
					<li>{"Anxiété et troubles dépressifs : phobies, troubles obsessionnels compulsifs, dépressions, crises d'angoisse"}</li>
					<li>{"Débordement des capacités psychiques : trop plein émotionel"}</li>
				</ul>
			)
		},
		{
			title: "En couple ou en famille",
			path: "famille",
			text: (
				<ul>
					<li>{"Crises de couple, impasses relationnelles"}</li>
					<li>{"Difficultés de communication"}</li>
					<li>{"Difficultés éducatives, besoin de guidance parentale"}</li>
				</ul>
			)
		},
		{
			title: "Chez l'adolescent",
			path: "adolescent",
			text: (
				<ul>
					<li>{"Troubles du comportement alimentaires : boulimie, anorexie"}</li>
					<li>{"Conduites addictives : tabac, alcool, subtances, écrans"}</li>
					<li>{"Conduites à risque"}</li>
					<li>{"Difficultés relationnelles : repli, aggressivité, problèmes de communication"}</li>
					<li>{"Échec scolaire, orientation difficile"}</li>
				</ul>
			)
		},
		{
			title: "Chez l'enfant",
			path: "enfant",
			text: (
				<ul>
					<li>{"Troubles du comportement : agitations, colères, cris, aggressivité, opposition, repli social"}</li>
					<li>{"Difficultés scolaires"}</li>
					<li>{"Événements de vie difficiles : séparation, deuil, déménagement, difficultés relationnelles"}</li>
					<li>{"Troubles de l'humeur : dépression, tristesse"}</li>
					<li>{"Troubles anxieux : sommeil perturbé, angoisse de séparation, troubles obsessionnels compulsifs, douleurs somatiques"}</li>
					<li>{"Troubles du comportement alimentaire : refus de manger, comportement sélectif"}</li>
					<li>{"Difficultés d'acquisition de l'autonomie, régressions"}</li>
				</ul>
			)
		},
		{
			title: "En entreprise",
			path: "entreprise",
			text: (
				<ul>
					<li>{"Dispositif groupal d'analyse des pratiques professionnelles"}</li>
					<li>{"Groupes de parole"}</li>
				</ul>
			)
		}
	];

	pageClassName() {
		return "why-page";
	}

	pageTitle() {
		return "Salwa El Farouk - Psychologue à Compiègne (Oise) - Pourquoi";
	}

	pageDescription() {
		return "Glossaire des principales raisons de consulter un psychologue";
	}

	renderTitle() {
		return "Consulter un psychologue";
	}
}
