import "firebase/auth";

// import database from "./database";
import instantiateUser from "./users/userFactory";

const callbacks = Symbol("callbacks");

// const firebase = database.firebase();

const database = {};
const firebase = {};

class Session {
	constructor() {
		this[callbacks] = [];

		/*
		 * firebase.auth().onAuthStateChanged((data) => {
		 * 	this[callbacks].forEach(([, loading]) => {
		 * 		loading();
		 * 	});
		 *
		 * 	if (!data) {
		 * 		this[callbacks].forEach(([callback]) => {
		 * 			callback(new Anonymous());
		 * 		});
		 * 		return;
		 * 	}
		 *
		 * 	database.getUser(data.email)
		 * 		.promise
		 * 		.then((user) => {
		 * 			this[callbacks].forEach(([callback]) => {
		 * 				callback(user);
		 * 			});
		 * 		});
		 * });
		 */
	}

	onUserChange({ callback, loading = () => {} }) {
		this[callbacks].push([callback, loading]);
	}

	currentUser() {
		let user = firebase.auth().currentUser;

		if (!user) {
			return instantiateUser(null);
		}

		return database.getUser(user.email)
			.promise;
	}

	login(email, password) {
		firebase.auth().signInWithEmailAndPassword(email, password)
			.catch((error) => {
				window.alert(error.message); // eslint-disable-line no-alert
			});
	}

	logout() {
		firebase.auth().signOut()
			.catch((error) => {
				window.alert(error.message); // eslint-disable-line no-alert
			});
	}
}

export default new Session();
