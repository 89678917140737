import "./book.scss";
import Translate, { Interpolate } from "../../components/translate.js";
import contacts from "../../helpers/contacts";

/*
 * import database from "../../models/database";
 * import { formatDay } from "../../helpers/formatTime";
 */

import features from "../../helpers/features";
import { Helmet } from "react-helmet";
import Loading from "../../components/loading/loading";
import PropTypes from "prop-types";
import React from "react";

const promises = Symbol("promises");
const MESSAGE_TIME = 3000;

const database = {}; // placeholder
const formatDay = () => {};

export default class Book extends React.Component {
	constructor() {
		super(...arguments); // eslint-disable-line prefer-rest-params

		this[promises] = [];
	}

	state = {
		config: null,
		date: new Date(),
		error: null,
		length: null,
		time: null,
		timeSlots: null,
		showModal: false
	};

	static propTypes = {
		currentUser: PropTypes.object.isRequired,
		showMessage: PropTypes.func.isRequired
	};

	updateData() {
		let promise = database.fetchTimeSlots(this.state.date);
		this[promises].push(promise);

		promise
			.promise
			.then((timeSlots) => {
				this.setState({ timeSlots });
			})
			.catch((error) => {
				if (!promise.hasCanceled()) {
					this.setState({ error });
				}
			});
	}

	componentDidMount() {
		/*
		 * let promise = database.fetchConfig("days");
		 * this[promises].push(promise);
		 * promise
		 * 	.promise
		 * 	.then((config) => {
		 * 		this.setState({ config });
		 * 	});
		 *
		 * this.updateData();
		 */
	}

	componentDidUpdate(__, previousState) {
		if (previousState.date !== this.state.date) {
			this.setState({ timeSlots: null }, () => {
				this.updateData();
			});
		}
	}

	componentWillUnmount() {
		this[promises].forEach((promise) => {
			promise.cancel();
		});
	}

	onTimePicked({ time, length }) {
		this.setState({
			time,
			length,
			showModal: true
		});
	}

	toggleModal() {
		this.setState(({ showModal }) => ({
			showModal: !showModal
		}));
	}

	onChangeDate(date) {
		this.setState({ date });
	}

	isBookable(date) {
		return (date.getDay() === 5) || // Friday
			(date.getDay() === 3 && date.getWeek() % 2); // Wednesday every other week
	}

	renderError() {
		if (!this.state.error) {
			return null;
		}

		setTimeout(() => {
			this.setState({ error: null });
		}, MESSAGE_TIME);

		return (
			<div className="alert alert-danger" role="alert">
				{this.state.error.message}
			</div>
		);
	}

	componentDidCatch(error) {
		this.setState({ error });
	}

	onAppointmentBooked(appointment) {
		let data = Object.assign({}, appointment, {
			date: formatDay(this.state.date),
			time: this.state.time
		});

		let promise = database.bookAppointment(data);

		promise.promise
			.then(() => {
				this.props.showMessage(<Translate i18nKey="book:appointment.booked">
					Rendez-vous pris avec succès
				</Translate>);
			})
			.catch((error) => {
				this.setState({ error });
			});

		this.setState({ showModal: false });
	}

	renderOffline() {
		return (
			<div className="page book offline">
				<Helmet>
					<title>Salwa El Farouk - Psychologue à Compiègne (Oise) - Réservation</title>
					<meta
						content={"Comment me joindre afin de convenir d'un rendez-vous"}
						name="description"/>
				</Helmet>
				<div>
					<Translate i18nKey="book:offline">
						Pour prendre rendez-vous, vous pouvez me contacter par courriel ou par téléphone au :
					</Translate>
				</div>
				<div className="contacts">
					<p>
						{contacts.phone()}
					</p>
					<p className="or">
						<Translate i18nKey="book:or">
							ou
						</Translate>
					</p>
					<p>
						{contacts.email()}
					</p>
				</div>
				<div>
					<Interpolate i18nKey="book:leave.message">
						N{"'"}hésitez pas à me laisser un message avec vos coordonnées <strong>(nom, prénom, numéro de
						téléphone)</strong> afin que je puisse vous recontacter au plus vite.
					</Interpolate>
				</div>
			</div>
		);
	}

	render() {
		if (!features.isOnlineBookingEnabled()) {
			return this.renderOffline();
		}

		if (!this.state.config || !this.state.date) {
			return <Loading/>;
		}

		return (
			<div>
				{this.renderError()}
			</div>
		);
	}
}
